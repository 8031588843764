import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'gl-visual-claim',
  templateUrl: './visual-claim.component.html',
  styleUrls: ['./visual-claim.component.css']
})
export class VisualClaimComponent implements OnInit {
  @Input() iconUrl: string
  @Input() title: string
  @Input() text: string

  constructor() { }

  ngOnInit(): void {
  }

}
