import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisualClaimComponent } from './visual-claim/visual-claim.component';
import { SummaryListItemComponent } from './summary-list-item/summary-list-item.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { H3FooterComponent } from './h3-footer/h3-footer.component';
import { H3HeaderComponent } from './h3-header/h3-header.component';
import { LoadingPipe } from './loading.pipe';

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule],
  declarations: [
    VisualClaimComponent,
    SummaryListItemComponent,
    H3FooterComponent,
    H3HeaderComponent,
    LoadingPipe
  ],
  exports: [
    VisualClaimComponent,
    SummaryListItemComponent,
    H3FooterComponent,
    H3HeaderComponent,
    LoadingPipe
  ]
})
export class SharedUiElementsModule {}
