import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '@greenliff/environment';
import { AuthCretentialsDto } from '@greenliff/shared/h3-model';

@Component({
  selector: 'gl-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  signinForm: FormGroup;
  errorMessage: string;

  constructor(private http: HttpClient, private router: Router) {}

  ngOnInit(): void {
    this.signinForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }

  onSubmit() {
    if (!this.signinForm.valid) {
      this.errorMessage =
        'Das Formular ist noch nicht korrekt ausgefüllt, bitte rot markierte Felder beachten';
      return;
    }

    this.errorMessage = '';

    const credentials: AuthCretentialsDto = {
      username: this.signinForm.get('username').value,
      password: this.signinForm.get('password').value
    };

    this.http
      .post<{ accessToken: string }>(
        `${environment.apiUrl}/auth/signin`,
        credentials
      )
      .subscribe(
        res => {
          localStorage.setItem('accessToken', res.accessToken);
          this.router.navigate(['/home']);
        },
        err => {
          const message = err.error?.message ? err.error.message : err.error;

          this.errorMessage = message;
        }
      );
  }
}
