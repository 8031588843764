import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import {
  H3HomeFeatureModule,
  h3HomeFeatureRoutes
} from '@greenliff/h3-home/feature';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { SigninComponent } from './signin/signin.component';

import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [AppComponent, LoginComponent, SigninComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    RouterModule.forRoot(
      [
        { path: 'home', children: h3HomeFeatureRoutes },
        { path: '', redirectTo: '/home', pathMatch: 'full' },
        { path: 'login', component: LoginComponent},
        { path: 'signin', component: SigninComponent},
        {
          path: 'position',
          loadChildren: () =>
            import('@greenliff/position/feature').then(
              module => module.PositionFeatureModule
            )
        },
        {
          path: 'resource',
          loadChildren: () =>
            import('@greenliff/resource/feature').then(
              module => module.ResourceFeatureModule
            )
        }
      ],
      { initialNavigation: 'enabled', relativeLinkResolution: 'legacy' }
    ),
    MsalModule.forRoot({
      auth: {
        clientId: 'a86481f9-140a-4cbe-9140-13dd2e9ddfa6',
        authority: 'https://login.microsoftonline.com/9da806c6-4570-4796-a56e-84a19b5e5159',
        redirectUri: 'http://localhost:4200/',
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true, 
      },
    },
    {
      popUp: !isIE,
      consentScopes: [
        'user.read',
        'openid',
        'profile',
      ],
      unprotectedResources: [],
      protectedResourceMap: [],
      extraQueryParameters: {}
    }),
    H3HomeFeatureModule,
    BrowserAnimationsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
