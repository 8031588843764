import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'gl-h3-home',
  templateUrl: './h3-home.component.html',
  styleUrls: ['./h3-home.component.css']
})
export class H3HomeComponent implements OnInit {
  expandedQuestion = '';
  
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  onClick(val) {
    if (val === 'openPosition') {
      this.router.navigate(['/position']);
    } else if (val === 'publishOpenPosition') {
      this.router.navigate(['/position/form']);
    } else if (val === 'openResource') {
      this.router.navigate(['/resource']);
    } else if (val === 'publishOpenResource') {
      this.router.navigate(['/resource/form']);
    }
  }
}
