import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import { H3HomeComponent } from './h3-home/h3-home.component';

import { SharedUiButtonsModule } from '@greenliff/shared/ui-buttons';
import { SharedUiElementsModule } from '@greenliff/shared/ui-elements';
import { MatIconModule } from '@angular/material/icon';
import { H3HeaderContainerComponent } from './h3-header-container/h3-header-container.component';
import { H3BreadcrumbComponent } from './h3-breadcrumb/h3-breadcrumb.component';

export const h3HomeFeatureRoutes: Route[] = [
  {
    path: '',
    component: H3HomeComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    SharedUiButtonsModule,
    SharedUiElementsModule
  ],
  declarations: [H3HomeComponent, H3HeaderContainerComponent, H3BreadcrumbComponent],
  exports: [H3HeaderContainerComponent, H3BreadcrumbComponent]
})
export class H3HomeFeatureModule {}
