<div class="container content">
    <div class="row">
        <div class="col-lg-12">
            <ul>
                <li [routerLink]="'/home'">Home</li>
                <li *ngFor="let p of path" [routerLink]="p.link">
                    > {{ p.text }}
                </li>
            </ul>
        </div>
    </div>
</div>