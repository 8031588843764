<div class="wrapper">
  <form [formGroup]="signinForm" (ngSubmit)="onSubmit()">
    <h2>Anmelden:</h2>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Username</mat-label>
        <input matInput placeholder="username" formControlName="username" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Password</mat-label>
        <input matInput type="password" placeholder="top secret" formControlName="password" />
      </mat-form-field>
    </div>
    <div class="gl-notification">
      <p class="gl-error">{{ errorMessage }}</p>
    </div>

    <button mat-stroked-button>LOGIN</button>
  </form>
</div>
