import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gl-h3-footer',
  templateUrl: './h3-footer.component.html',
  styleUrls: ['./h3-footer.component.css']
})
export class H3FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
