<gl-h3-header-container></gl-h3-header-container>
<section class="gl-first-section">
    <div class="container content">
        <div class="row">
            <div class="col-lg-12">
                <div class="col-lg-6 gl-section-1">
                    <div>
                        <p>Während viele Detailhandelsgeschäfte in der Schweiz schliessen oder stark reduzieren mussten,
                            sind die
                            Lebensmittelhändler von der Nachfrage überwältigt und stehen vor personellen Engpässen.</p>
                        <p>Die Swiss Retail Federation ruft mit der Initiative «Handel hilft Handel» zur Solidarität
                            auf. Auf dieser Plattform können Händler Mitarbeitende kurzfristig und unkompliziert von
                            anderen Unternehmen ausleihen oder ihre Mitarbeitenden selbst zur Verfügung stellen.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="gl-grey-section">
    <div class="container content">
        <div class="row">
            <div class="col-lg-12">
                <div class="button-grid">
                    <div>
                        <h3>INSERATE DURCHSUCHEN</h3>
                        <gl-icon-button [text]="'OFFENE STELLEN'" (clicked)="onClick('openPosition')"
                            [iconUrl]="'search'">
                        </gl-icon-button>
                        <gl-icon-button [text]="'VERFÜGBARE MITARBEITER'" (clicked)="onClick('openResource')"
                            [iconUrl]="'search'">
                        </gl-icon-button>
                    </div>
                    <div>
                        <h3>GRATIS INSERIEREN</h3>
                        <gl-icon-button [text]="'OFFENE STELLEN ERFASSEN'" (clicked)="onClick('publishOpenPosition')"
                            [iconUrl]="'person'">
                        </gl-icon-button>
                        <gl-icon-button [text]="'VERFÜGBARE MITARBEITER ERFASSEN'"
                            (clicked)="onClick('publishOpenResource')" [iconUrl]="'person'">
                        </gl-icon-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container content">
        <div class="row">
            <div class="col-lg-12">
                <h2>SO FUNKTIONIERT'S</h2>
                <div class="how-grid">
                    <gl-visual-claim [title]="'FÜR NON-FOOD HÄNDLER'" [iconUrl]="'/assets/img/non-food-haendler.png'"
                        [text]="'Interessierte Non-Food Händler können ihre Belegschaft temporär zur Verfügung stellen oder gezielt suchenden Food-Händlern anbieten.'">
                    </gl-visual-claim>
                    <gl-visual-claim [title]="'FÜR FOOD HÄNDLER'" [iconUrl]="'/assets/img/food-haendler.png'"
                        [text]="'Lebensmittelhändler mit Personalengpässen, können gezielt Mitarbeitende suchen oder offenen Stellen ausschreiben.'">
                    </gl-visual-claim>
                    <gl-visual-claim [title]="'MATCH-MAKING'" [iconUrl]="'/assets/img/match-making.png'"
                        [text]="'Händler nehmen über die Plattform Kontakt auf und klären mit Unterstützung der Swiss Retail Federation die Vertragsmodalitäten.'">
                    </gl-visual-claim>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="gl-grey-section">
    <div class="container content">
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8">
                <h2>FAQS</h2>
                <hr>
                <div class="faq">
                    <div class="gl-faq-item">
                        <mat-icon (click)="expandedQuestion = 'q1'" *ngIf="expandedQuestion !== 'q1'" class="gl-icon" aria-hidden="false"
                            aria-label="Example home icon">chevron_right
                        </mat-icon>
                        <mat-icon (click)="expandedQuestion = 'q1'" *ngIf="expandedQuestion === 'q1'" class="gl-icon" aria-hidden="false"
                            aria-label="Example home icon">expand_more
                        </mat-icon>
                        <h4 (click)="expandedQuestion = 'q1'">Auf welcher gesetzlichen Basis werden die Verträge
                            geschlossen?</h4>
                        <div class="faq-answer" *ngIf="expandedQuestion === 'q1'">
                            <p>Die Swiss Retail Federation bietet Vorlagen für einen Überlassungsvertrag, sowie einen
                                Zusatz zum bestehenden Arbeitsvertrag zum Download an. Darüber hinaus schafft ein
                                Gentlemens Agreement weitere Sicherheit, beispielsweise indem es direktes Abwerben der
                                Arbeitnehmer verhindert. Die Vertragsvorlagen senden wir Ihnen nach Kontaktaufnahme per
                                E-Mail zu.</p>
                            <p>Non-Food Händler erhalten zusätzlich einen Beispielbrief für den Aufruf an freiwillige
                                Mitarbeitende.
                            </p>
                        </div>
                    </div>
                    <hr>
                    <div class="gl-faq-item">
                        <mat-icon (click)="expandedQuestion = 'q2'" *ngIf="expandedQuestion !== 'q2'" class="gl-icon" aria-hidden="false"
                            aria-label="Example home icon">chevron_right
                        </mat-icon>
                        <mat-icon (click)="expandedQuestion = 'q2'" *ngIf="expandedQuestion === 'q2'" class="gl-icon" aria-hidden="false"
                            aria-label="Example home icon">expand_more
                        </mat-icon>
                        <h4 (click)="expandedQuestion = 'q2'">Wie verläuft die Lohnabwicklung?</h4>
                        <div class="faq-answer" *ngIf="expandedQuestion === 'q2'">
                            <p>Der Arbeitsvertrag des Mitarbeitenden mit dem Verleiher (Arbeitgeber) bleibt bestehen,
                                d.h.
                                alle
                                Sozialversicherungen, Lohnzahlungen etc. werden über den bestehenden Arbeitgeber
                                abgewickelt. Der Einsatzbetrieb (Nutzniesser) bezahlt dem Verleiher eine Entschädigung,
                                welche diese Kosten
                                abdeckt. Im Gegenzug erhält er den Arbeitnehmer und das
                                Weisungsrecht für eine bestimmte Zeit.</p>
                        </div>
                    </div>
                    <hr>
                    <div class="gl-faq-item">
                        <mat-icon (click)="expandedQuestion = 'q3'" *ngIf="expandedQuestion !== 'q3'" class="gl-icon" aria-hidden="false"
                            aria-label="Example home icon">chevron_right
                        </mat-icon>
                        <mat-icon (click)="expandedQuestion = 'q3'" *ngIf="expandedQuestion === 'q3'" class="gl-icon" aria-hidden="false"
                            aria-label="Example home icon">expand_more
                        </mat-icon>
                        <h4 (click)="expandedQuestion = 'q3'">Welche Kosten entstehen für den Verleiher?</h4>
                        <div class="faq-answer" *ngIf="expandedQuestion === 'q3'">
                            <p>Grundsätzlich entstehen keine Kosten. Lohnzahlungen, Sozialleistungen, etc. werden
                                weiterhin über den Verleiher (bestehenden Arbeitgeber) abgewickelt. Deshalb ist es
                                denkbar, dass eine allfällige «Leihgebühr» zwischen beiden Parteien bilateral verhandelt
                                wird. Der entstehende extra Aufwand beim Verleiher würde dann durch diese
                                «Leihgebühr» abgedeckt.</p>
                            <p>Mit Blick auf die besondere Situation appelliert die Swiss Retail Federation an die
                                Solidarität
                                der Unternehmen.
                                Der Verleih soll nicht durch finanzielle Anreize zustande kommen.</p>
                        </div>
                    </div>
                    <hr>
                    <div class="gl-faq-item">
                        <mat-icon (click)="expandedQuestion = 'q4'" *ngIf="expandedQuestion !== 'q4'" class="gl-icon" aria-hidden="false"
                            aria-label="Example home icon">chevron_right
                        </mat-icon>
                        <mat-icon (click)="expandedQuestion = 'q4'" *ngIf="expandedQuestion === 'q4'" class="gl-icon" aria-hidden="false"
                            aria-label="Example home icon">expand_more
                        </mat-icon>
                        <h4 (click)="expandedQuestion = 'q4'">Meine Mitarbeitenden befinden sich momentan in
                            Kurzarbeit, kann ich die
                            Plattform trotzdem nutzen und einem
                            Lebensmittelhändler helfen?</h4>
                        <div class="faq-answer" *ngIf="expandedQuestion === 'q4'">
                            <p>Ja, Sie können die Plattform trotzdem nutzen und helfen. Melden Sie einfach Ihre
                                Mitarbeitenden wieder von der Kurzarbeit ab. Da der Bundesrat die Voranmelde- und
                                Karenzfristen gestrichen hat, ist dies verzugslos möglich. Nach Ablauf der Personalleihe
                                melden Sie Ihre Mitarbeitenden wieder zur Kurzarbeit an, sofern dieser Bedarf noch
                                besteht.
                            </p>
                        </div>
                    </div>
                    <hr>
                    <div class="gl-faq-item">
                        <mat-icon (click)="expandedQuestion = 'q5'" *ngIf="expandedQuestion !== 'q5'" class="gl-icon" aria-hidden="false"
                            aria-label="Example home icon">chevron_right
                        </mat-icon>
                        <mat-icon (click)="expandedQuestion = 'q5'" *ngIf="expandedQuestion === 'q5'" class="gl-icon" aria-hidden="false"
                            aria-label="Example home icon">expand_more
                        </mat-icon>
                        <h4 (click)="expandedQuestion = 'q5'">Was passiert, wenn der Mitarbeitende nur Teilzeit
                            eingesetzt
                            werden kann,
                            bei uns jedoch ein 100% Pensum
                            hätte?</h4>
                        <div class="faq-answer" *ngIf="expandedQuestion === 'q5'">
                            <p>Die Mitarbeitenden werden zu der betreffenden Prozentzahl des Teilzeitpensums von der
                                Kurzarbeit befreit. Von den übrigen Stellenprozenten werden den Mitarbeitenden 80% über
                                die Kurzarbeit bezahlt. </p>
                        </div>
                    </div>
                    <hr>
                    <div class="gl-faq-item">
                        <mat-icon (click)="expandedQuestion = 'q6'" *ngIf="expandedQuestion !== 'q6'" class="gl-icon" aria-hidden="false"
                            aria-label="Example home icon">chevron_right
                        </mat-icon>
                        <mat-icon (click)="expandedQuestion = 'q6'" *ngIf="expandedQuestion === 'q6'" class="gl-icon" aria-hidden="false"
                            aria-label="Example home icon">expand_more
                        </mat-icon>
                        <h4 (click)="expandedQuestion = 'q6'">Kann der Einsatz bei direkten Mitbewerbern vertraglich
                            ausgeschlossen
                            werden?</h4>
                        <div class="faq-answer" *ngIf="expandedQuestion === 'q6'">
                            <p>Ja. Es findet kein automatisiertes Match-Making statt. Sie können individuell
                                entscheiden, mit wem Sie zusammenarbeiten möchten. Bei den offenen Stellen bzw.
                                verfügbaren Ressourcen ist stets ersichtlich, wer der Absender ist, sodass Sie
                                Mitbewerber ausschliessen können.</p>
                        </div>
                    </div>
                    <hr>
                    <div class="gl-faq-item">
                        <mat-icon (click)="expandedQuestion = 'q7'" *ngIf="expandedQuestion !== 'q7'" class="gl-icon" aria-hidden="false"
                            aria-label="Example home icon">chevron_right
                        </mat-icon>
                        <mat-icon (click)="expandedQuestion = 'q7'" *ngIf="expandedQuestion === 'q7'" class="gl-icon" aria-hidden="false"
                            aria-label="Example home icon">expand_more
                        </mat-icon>
                        <h4 (click)="expandedQuestion = 'q7'">Gibt es eine Klausel, damit sichergestellt wird, dass
                            Mitarbeitende nicht
                            abgeworben werden dürfen?</h4>
                        <div class="faq-answer" *ngIf="expandedQuestion === 'q7'">
                            <p>Dies ist in der Vorlage des Überlassungsvertrags nicht explizit geregelt. Die Swiss
                                Retail
                                Federation verweist hierzu auf das Gentlemens Agreement (Code of Conduct), zu welchem
                                sich die beiden jeweiligen Parteien bekennen können. Selbstverständlich können bilateral
                                weitere Klauseln zu den Verträgen hinzugefügt werden.</p>
                        </div>
                    </div>
                </div>
                <hr>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container content">
        <div class="row">
            <div class="col-lg-12">
                <h2>KONTAKT</h2>
                <div class="kontakt-grid">
                    <div>
                        <span class="kontakt-line">Swiss Retail Federation</span>
                        <span class="kontakt-line">Bahnhofplatz 1</span>
                        <span class="kontakt-line">3011 Bern</span>
                        <a href="mailto:info@swiss-retail.ch" class="kontakt-line">info@swiss-retail.ch</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<gl-h3-footer></gl-h3-footer>