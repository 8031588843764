import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'gl-h3-header-container',
  templateUrl: './h3-header-container.component.html',
  styleUrls: ['./h3-header-container.component.css']
})
export class H3HeaderContainerComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  onHomeClicked() {
    this.router.navigate(['/home']);
  }
}
