<div class="container content">
    <div class="row">
        <div class="col-lg-12 gl-header">
            <h1 (click)="onHomeClick()">Handel hilft Handel</h1>
            <div></div>
            <a href="https://www.swiss-retail.ch/" target="_blank">
                <img src="/assets/img/SwissRetail_Logo_Claim.png" alt="Swiss Retail Federation">
            </a>
        </div>
    </div>
</div>