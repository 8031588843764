import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gl-h3-header',
  templateUrl: './h3-header.component.html',
  styleUrls: ['./h3-header.component.css']
})
export class H3HeaderComponent implements OnInit {
  @Output() homeClicked = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onHomeClick() {
    this.homeClicked.emit();
  }
}
