import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PositionDto } from '@greenliff/shared/h3-model';

@Component({
  selector: 'gl-summary-list-item',
  templateUrl: './summary-list-item.component.html',
  styleUrls: ['./summary-list-item.component.css']
})
export class SummaryListItemComponent implements OnInit {
  @Input() position: PositionDto;
  @Output() detailClicked = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onDetailClick() {
    this.detailClicked.emit({
      rowKey: this.position.RowKey
    });
  }
}
