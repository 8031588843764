export class PositionDto {
    RowKey: string;
    company: string;
    companyLogo?: string;
    companyUrl?: string;
    contactName: string;
    contactTelefon?: string;
    contactEmail: string;
    region: string;
    category: string;
    amount: number;
    workload?: string;
    availability?: Date;
    title: string;
    teaser?: string;
    requirements?: string;
    tasks?: string;
    comment?: string;
    online: boolean;
}

export class ResourceDto {
    RowKey: string;
    company: string;
    companyLogo?: string;
    companyUrl?: string;
    contactName: string;
    contactTelefon?: string;
    contactEmail: string;
    region: string;
    category: string;
    amount: number;
    workload?: string;
    availability?: Date;
    title: string;
    teaser?: string;
    skills?: string;
    comment?: string;
    online: boolean;
}

export interface AuthCretentialsDto {
    username: string;
    password: string;
}