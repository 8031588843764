<div
  class="list-item"
  [ngStyle]="{ 'background-color': position.online ? '#ffffff' : '#D5D5D5' }"
>
  <div class="logo-wrapper">
    <img [src]="position.companyLogo" alt="" />
  </div>
  <div>
    <h2>{{ position.title }}</h2>
    <p>{{ position.teaser }}</p>
    <div class="icons-grid">
      <mat-icon aria-hidden="false" aria-label="Example home icon"
        >people</mat-icon
      >
      <span>{{ position.amount }}</span>
      <mat-icon aria-hidden="false" aria-label="Example home icon"
        >location_on</mat-icon
      >
      <span>{{ position.region }}</span>
      <mat-icon aria-hidden="false" aria-label="Example home icon"
        >extension</mat-icon
      >
      <span>{{ position.category }}</span>
      <mat-icon aria-hidden="false" aria-label="Example home icon"
        >access_time</mat-icon
      >
      <span>{{ position.workload }}</span>
      <mat-icon aria-hidden="false" aria-label="Example home icon"
        >calendar_today</mat-icon
      >
      <span>{{ position.availability | date: 'dd.MM.yyyy' }}</span>
      <div></div>
    </div>
  </div>
  <div class="button-wrapper">
    <a href="mailto:info@swiss-retail.ch" class="kontakt-line">
      <button mat-stroked-button>KONTAKT</button>
    </a>
    <button mat-stroked-button (click)="onDetailClick()">
      DETAIL
    </button>
  </div>
</div>
