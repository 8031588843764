import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'gl-h3-breadcrumb',
  templateUrl: './h3-breadcrumb.component.html',
  styleUrls: ['./h3-breadcrumb.component.css']
})
export class H3BreadcrumbComponent implements OnInit {
  @Input() path: Array<{link: string, text: string}>;

  constructor() { }

  ngOnInit(): void {
  }

}
